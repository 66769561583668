
.whatsapp {
 position: fixed; 
 right:25px; /*Margen derecho*/
 bottom:20px; /*Margen abajo*/
 z-index:999;
}
.whatsapp img {
 width:60px; /*Alto del icono*/
 height:60px; /*Ancho del icono*/
}
.whatsapp:hover{
opacity: 0.7 !important;
filter: alpha(opacity=70) !important;
}